/* eslint-disable react-hooks/exhaustive-deps */
import styled from '@emotion/styled';
import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatchToStore, useSelectorToStore } from '@hooks/reduxHooks';
import { useRouter } from 'next/router';
import { checkCoursesEnrollStatus } from '@actions/courses';
import Dynamic from '@base/Dynamic/Dynamic';
import useLoginStore from '@stores/login/loginStore';
import { getSetInitialAccountType } from '@stores/selectors/login';
import constants from '@cont/Login/constants';
import { LOGIN_DRAWER } from '@constants/toggleables';
import { useToggleable } from '@stores/toggleables';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getIsLoggedIn } from '@api/selectors/auth';
import AdditionalInputs from '@cont/ScholarshipTest/AdditionalInputs';
import {
  ProdigyHeader,
  HowToCrack,
  StarPoints,
  EducatorsList,
  ShareBlock,
  // AboutProdigy,
  ProdigyFAQs,
  AppInstall
} from '.';
import PromotionalBanner from './PromotionalBanner';

const GoalWiseTestSeries = Dynamic(() => import('./GoalWiseTestSeries'));

const Wrapper = styled.div`
  width: 100vw;
  margin-top: 100px;
  @media only screen and (max-width: 540px) {
    width: 100vw;
    overflow-x: hidden;
    margin-top: 56px;
  }
`;

const ProdigyContainer = ({ data, testSeriesKey }) => {
  const [additionalDetailsOpen, setAdditionalDetailsOpen] = useState(false);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const goalList = useRef();
  const dispatch = useDispatchToStore();
  const router = useRouter();
  const me = useSelectorToStore((state) => state.auth.me);

  const { testSeriesUIDs, additionalInputs, goalData } = data;

  useEffect(() => {
    if (me.username) {
      dispatch(checkCoursesEnrollStatus(testSeriesUIDs));
    }
  }, [dispatch, me.username]);
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const { openElement: openLoginDrawer } = useToggleable(LOGIN_DRAWER);
  const setInitialAccountType = useLoginStore(getSetInitialAccountType);
  const [openAdditional, setOpenAdditional] = useState(false);
  const handleSignup = () => {
    setInitialAccountType(constants.ACCOUNT_TYPE_SIGNUP);
    openLoginDrawer();
    setOpenAdditional(true);
  };

  const setOpenAdditiaonalData = () => {
    setOpenAdditional(true);
  };
  useEffect(() => {
    if (openAdditional && isLoggedIn) {
      setAdditionalDetailsOpen(true);
    }
  }, [openAdditional, isLoggedIn]);

  const enrollAction = () => {
    setAdditionalDetailsOpen(false);
    setIsEnrolled(true);
  };

  const onEnrollNowClick = () => {
    if (!isEnrolled) {
      setAdditionalDetailsOpen(true);
    }
    //     .getElementById('goalwise-testseries')
    //     .scrollIntoView({ behavior: 'smooth', block: 'center' });
    // }
  };

  const hasTests = Object.keys(data.tests).length > 0;
  const hasClasses = Object.keys(data.classes).length > 0;

  return (
    <Wrapper>
      <ProdigyHeader
        isEnrolled={isEnrolled}
        enrollNow={isLoggedIn ? onEnrollNowClick : handleSignup}
        dataSet={data}
      />
      {additionalDetailsOpen && (
        <AdditionalInputs
          drawerState={additionalDetailsOpen}
          closeDrawerHandler={() => setAdditionalDetailsOpen(false)}
          additionalInputs={additionalInputs}
          eventKey={testSeriesKey}
          goalData={goalData}
          testSeriesUIDs={testSeriesUIDs}
          callback={enrollAction}
        />
      )}
      {data.promotionalBanner1 && (
        <PromotionalBanner data={data.promotionalBanner1} />
      )}
      <HowToCrack enrollNow={onEnrollNowClick} dataSet={data} />
      {hasTests && (
        <GoalWiseTestSeries
          setOpenAdditiaonalData={setOpenAdditiaonalData}
          ref={goalList}
          items={data.tests}
          goalData={data.goalData}
          id="goalwise-testseries"
          title="Pick the right test for you"
          cardType="test"
          data={data}
        />
      )}
      {hasClasses && (
        <GoalWiseTestSeries
          setOpenAdditiaonalData={setOpenAdditiaonalData}
          ref={goalList}
          items={data.classes}
          goalData={data.goalData}
          title="Pick the right class for you"
          cardType="class"
          id="goalwise-testseries"
          data={data}
        />
      )}
      {data.promotionalBanner2 && (
        <PromotionalBanner data={data.promotionalBanner2} />
      )}
      <StarPoints enrollNow={onEnrollNowClick} dataSet={data} />
      <EducatorsList
        enrollNow={onEnrollNowClick}
        disable={router.route !== 'prodigy'}
      />
      <ShareBlock data={data.shareData} />
      {/* <AboutProdigy enrollNow={onEnrollNowClick} dataSet={data} /> */}
      <ProdigyFAQs enrollNow={onEnrollNowClick} dataSet={data} />
      <AppInstall />
    </Wrapper>
  );
};

ProdigyContainer.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ProdigyContainer;
