import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import Drawer from '@base/Drawer/Drawer';
import TextField from '@base/TextField/TextField';
import Dropdown from '@comp/Dropdown/Dropdown';
import Button from '@base/Button/Button';
import H3 from '@base/H3/H3';
import P1 from '@base/P1/P1';
import { Fonts, mediaQuery, Spacings } from '@styles/index';
import useScholarshipEventDetails from '@api/hooks/scholarship/useScholarshipEventDetails';
import { saveProdigyAdditionalData } from '@actions/others';
import { segment } from '@actions/analytics';
import { GTM_TAP_ENROLL } from '@constants/segment';
import {
  IITJEE_APPLICATION_NUMBER_QUESTION_ID,
  IITJEE_DOB_QUESTION_ID
} from './constants';

const Content = styled.div`
  grid-column: span 10;
  padding-right: ${Spacings.SPACING_6B};
  .dropdown-button,
  .dropdown-button text {
    height: 56px;
    padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_6B};
    font-weight: ${Fonts.SEMIBOLD};
    color: var(--color-text-primary);
    ${mediaQuery.uptoTablet} {
      width: 100%;
    }
    h6 {
      color: var(--color-text-primary);
      opacity: 0.7;
      ${mediaQuery.uptoTablet} {
        font-size: ${Fonts.SMALL};
      }
    }
  }
  li {
    padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_6B};
  }
`;

const StyledP2 = styled.p`
  font-weight: ${Fonts.SEMIBOLD};
  font-size: ${Fonts.P1};
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  color: ${({ color }) => color};
  margin-bottom: ${Spacings.SPACING_2B};
`;

const InputContainer = styled.div`
  padding: 12px 0;
`;
const Title = styled(H3)`
  margin-bottom: ${Spacings.SPACING_2B};
`;

const SubTitle = styled(P1)`
  margin-bottom: ${Spacings.SPACING_8B};
`;

const StyledInput = styled(TextField)`
  margin-bottom: ${(props) =>
    props.applicationNumberError ? Spacings.SPACING_2 : Spacings.SPACING_4B};
  background-color: var(--color-base-1);
  .MuiInputBase-input {
    color: var(--color-text-primary);
    font-size: ${Fonts.SMALL};
    font-weight: ${Fonts.SEMIBOLD};
    ::placeholder {
      color: var(--color-text-primary);
      opacity: 0.7;
      font-size: 14px;
      font-weight: ${Fonts.SEMIBOLD};
    }
  }
  .MuiOutlinedInput-input {
    padding: ${Spacings.SPACING_5B} ${Spacings.SPACING_6B} !important;
  }
  .MuiInputBase-input > p {
    margin-top: 8px !important;
    margin-bottom: 4px !important;
  }
`;

const StyledInputDate = styled(TextField)`
  margin-bottom: ${(props) =>
    props.dateError ? Spacings.SPACING_2 : Spacings.SPACING_4B};
  background-color: var(--color-base-1);
  margin-top: ${(props) =>
    props.applicationNumberError ? Spacings.SPACING_4B : 'initial'};
  .MuiInputBase-input {
    color: var(--color-text-primary);
    font-size: ${Fonts.SMALL};
    font-weight: ${Fonts.SEMIBOLD};
    ::placeholder {
      color: var(--color-text-primary);
      opacity: 0.7;
      font-size: 14px;
      font-weight: ${Fonts.SEMIBOLD};
    }
  }
  .MuiOutlinedInput-input {
    padding: ${Spacings.SPACING_5B} ${Spacings.SPACING_6B} !important;
  }
  .MuiInputBase-input > p {
    margin-top: 8px !important;
    margin-bottom: 4px !important;
  }
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacings.SPACING_10B};
  margin-bottom: ${Spacings.SPACING_2B};
`;

const AdditionalInputs = ({
  drawerState,
  closeDrawerHandler,
  callback,
  additionalInputs,
  goalData,
  testSeriesUIDs,
  eventKey
}) => {
  const dispatch = useDispatch();
  const { mutate: refetchScholarshipTestDetails } =
    useScholarshipEventDetails(eventKey);

  const [inputData, setInputData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [applicationNumberError, setApplicationNumberError] = useState('');
  const [dateError, setDateError] = useState('');
  const submitDetails = () => {
    let error = null;
    let appNumberError = '';
    let dobError = '';
    setErrorMessage(null);
    if (
      Object.keys(inputData).length !== additionalInputs.length ||
      Object.keys(inputData).filter((el) => inputData[el] === '').length
    ) {
      error = 'Please enter all the fields';
    }
    if (
      inputData[IITJEE_APPLICATION_NUMBER_QUESTION_ID] &&
      (String(inputData[IITJEE_APPLICATION_NUMBER_QUESTION_ID]).length !== 12 ||
        !String(inputData[IITJEE_APPLICATION_NUMBER_QUESTION_ID]).startsWith(
          '24'
        ))
    ) {
      appNumberError =
        'Please enter a valid application number starting with 24';
    }
    const datePattern =
      /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;

    if (
      inputData[IITJEE_DOB_QUESTION_ID] &&
      !datePattern.test(String(inputData[IITJEE_DOB_QUESTION_ID]))
    ) {
      dobError = 'Please enter a date in dd/mm/yyyy';
    }
    setErrorMessage(error);
    setApplicationNumberError(appNumberError);
    setDateError(dobError);
    if (error || appNumberError || dobError) return;
    dispatch(
      saveProdigyAdditionalData({
        prodigy_key: eventKey,
        user_response: Object.keys(inputData).map((input) => {
          return {
            question: additionalInputs.find((el) => el.id === Number(input))
              .questionText,
            answer:
              typeof inputData[input] === 'object'
                ? inputData[input].name
                : inputData[input]
          };
        })
      })
    ).then(() => {
      const goalIds = [];
      const goalName = [];
      goalData.forEach((goal) => {
        goalIds.push(goal.id);
        goalName.push(goal.name);
      });
      const analyticsProps = {
        'Test Uid': testSeriesUIDs[0] || '',
        'Goal Id': goalIds.length ? goalIds.join(',') : '',
        'Goal Name': goalName.length ? goalName.join(',') : ''
      };
      segment.track(GTM_TAP_ENROLL, analyticsProps);

      refetchScholarshipTestDetails();
      closeDrawerHandler();
      if (callback) callback();
    });
  };
  return (
    <Drawer
      drawerState={drawerState}
      onClose={closeDrawerHandler}
      onPressBack={closeDrawerHandler}
    >
      <Content>
        <Title>A few details before you enroll</Title>
        <SubTitle>Please enter the required details</SubTitle>
        {additionalInputs.map((question) => {
          return (
            <>
              {question.answerType === 'text' &&
                question.id === IITJEE_APPLICATION_NUMBER_QUESTION_ID && (
                  <InputContainer>
                    <StyledP2>{question.questionText}</StyledP2>
                    <StyledInput
                      applicationNumberError={applicationNumberError}
                      fullWidth
                      placeholder=""
                      value={inputData[question.id]}
                      inputProps={{
                        type: 'tel',
                        maxLength: 12,
                        minLength: 12,
                        required: true,
                        autocomplete: 'off'
                      }}
                      type="tel"
                      error={applicationNumberError !== ''}
                      // Show the error message using the helperText prop
                      helperText={applicationNumberError}
                      onChange={(event) => {
                        const value = event.target.value.replace(/ /g, '');
                        if (value.match(/^\d+$/) || value === '') {
                          setInputData({
                            ...inputData,
                            [question.id]: value
                          });
                        }

                        // Clear the error when the length of the input is 12
                        if (
                          question.id ===
                            IITJEE_APPLICATION_NUMBER_QUESTION_ID &&
                          value.length === 12
                        ) {
                          setApplicationNumberError('');
                        }
                      }}
                    />
                  </InputContainer>
                )}
              {question.answerType === 'text' &&
                question.id === IITJEE_DOB_QUESTION_ID && (
                  <InputContainer>
                    <StyledP2>{question.questionText}</StyledP2>
                    <StyledInputDate
                      dateError={dateError}
                      applicationNumberError={applicationNumberError}
                      fullWidth
                      placeholder=""
                      value={inputData[question.id]}
                      inputProps={{
                        type: 'text', // Change this to 'text' to allow slashes
                        maxLength: 10, // Change this to 10 to allow 'dd/mm/yyyy'
                        minLength: 10, // Change this to 10 to allow 'dd/mm/yyyy'
                        required: true,
                        autocomplete: 'off'
                      }}
                      type="text" // Change this to 'text' to allow slashes
                      error={dateError !== ''}
                      helperText={dateError}
                      onChange={(event) => {
                        const value = event.target.value.replace(/[^\d/]/g, '');
                        setInputData({
                          ...inputData,
                          [question.id]: value
                        });
                        const datePattern =
                          /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;

                        if (
                          question.id === IITJEE_DOB_QUESTION_ID &&
                          datePattern.test(value)
                        ) {
                          setDateError('');
                        }
                      }}
                    />
                  </InputContainer>
                )}
              {question.id !== IITJEE_APPLICATION_NUMBER_QUESTION_ID &&
                question.id !== IITJEE_DOB_QUESTION_ID &&
                question.answerType === 'text' && (
                  <InputContainer>
                    <StyledP2>{question.questionText}</StyledP2>
                    <StyledInput
                      fullWidth
                      placeholder=""
                      value={inputData[question.id]}
                      onChange={(event) =>
                        setInputData({
                          ...inputData,
                          [question.id]: event.target.value
                        })
                      }
                    />
                  </InputContainer>
                )}
              {question.answerType === 'dropdown' && (
                <InputContainer>
                  <StyledP2>{question.questionText}</StyledP2>
                  <Dropdown
                    options={question.options}
                    placeholder=""
                    selectedOption={inputData[question.id]}
                    onOptionClick={(option) =>
                      setInputData({
                        ...inputData,
                        [question.id]: option
                      })
                    }
                    buttonWidth="440px"
                    menuWidth="440px"
                    className="dropdown-button"
                  />
                </InputContainer>
              )}
            </>
          );
        })}
        <StyledButton label="Continue" onClick={submitDetails} />
        {errorMessage ? (
          <P1 color="var(--color-i-red)">{errorMessage}</P1>
        ) : null}
      </Content>
    </Drawer>
  );
};

export default AdditionalInputs;
