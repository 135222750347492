/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { useState, forwardRef } from 'react';
import styled from '@emotion/styled';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import Menu from '@base/Menu/Menu';
import H6 from '@base/H6/H6';
import KeyboardArrowDown from '@icon/KeyboardArrowDown';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import { Spacings, Fonts, breakpoints } from '@styles/index';
import MenuItem from './MenuItem';

const DropDownButton = styled.button`
  display: flex;
  justify-content: space-between;
  min-width: fit-content;
  align-self: center;
  align-items: center;
  line-height: 150%;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  transition: all 400ms ease-in-out;
  border-width: 1px;
  border-style: solid;
  font-weight: 600;
  border-radius: ${Spacings.SPACING_2B};
  background-color: var(--color-base-1);
  grid-column: ${({ gridColumn }) => gridColumn};
  padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
  font-size: ${Fonts.SMALL};
  color: var(--color-text-primary);
  box-shadow: ${boxShadowGenerator()};
  border-color: ${({ showSelectedBorder, open }) => {
    if (showSelectedBorder) return 'var(--color-text-primary)';
    if (open) return 'var(--color-divider)';
    return 'var(--color-shadow)';
  }};
  ${({ buttonWidth }) => buttonWidth && `width: ${buttonWidth};`}
  :hover {
    background-color: var(--color-base-1);
    border-color: ${({ showSelectedBorder }) =>
      showSelectedBorder
        ? 'var(--color-text-primary)'
        : 'var(--color-divider)'};
    box-shadow: ${boxShadowGenerator({
      yOffset: Spacings.SPACING_2B,
      blur: Spacings.SPACING_2B
    })};
  }
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
    padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
    white-space: nowrap;
    grid-column: ${({ mwGridColumn }) => mwGridColumn || 'auto'} !important;
  }
`;

const Button = forwardRef(
  (
    {
      open,
      onClick,
      gridColumn,
      mwGridColumn,
      children,
      showSelectedBorder,
      className,
      buttonWidth,
      $isDisabled
    },
    ref
  ) => (
    <DropDownButton
      open={open}
      onClick={onClick}
      gridColumn={gridColumn}
      mwGridColumn={mwGridColumn}
      ref={ref}
      showSelectedBorder={showSelectedBorder}
      className={className}
      type="button"
      buttonWidth={buttonWidth}
      $isDisabled={$isDisabled}
    >
      {children}
    </DropDownButton>
  )
);

const StyledArrowDown = styled(KeyboardArrowDown)`
  transition: transform 400ms ease-in-out;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  @media only screen and (max-width: 47.95em) {
    margin-left: 8px;
  }
`;

const IconWrapper = styled.div`
  margin-right: ${Spacings.SPACING_2B};
  display: flex;
`;

const getFavoriteKey = (option) =>
  ['value', 'code', 'id', 'uid'].find((key) =>
    Object.keys(option || {}).some((k) => k === key)
  );

const selectedOptionHasValueOrId = (selectedOption) => {
  const favoriteKey = getFavoriteKey(selectedOption);
  return !isNil(selectedOption?.[favoriteKey]);
};

const isDefined = (value) => !(value === undefined || value === null);

const isSelected = (selectedOption, option) => {
  const favoriteKey = getFavoriteKey(selectedOption);
  if (
    isDefined(selectedOption?.[favoriteKey]) &&
    selectedOption?.[favoriteKey] === option?.[favoriteKey]
  )
    return true;
  return false;
};

const Dropdown = forwardRef((props, ref) => {
  const {
    options = [],
    selectedOption,
    onOptionClick,
    onClick,
    displayCheck,
    children,
    gridColumn,
    mwGridColumn,
    links,
    isDisabled,
    stripLength,
    showSelectedBorder,
    className,
    openDropDown,
    closeDropdown,
    state,
    useOuterState,
    displayIcon,
    menuWidth,
    menuHeight,
    buttonWidth,
    placeholder,
    menuColor,
    topChildren,
    showIcon,
    textColor,
    getKey,
    disablePortal
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    onClick();
    if (isDisabled) {
      return;
    }
    if (useOuterState) openDropDown(event.currentTarget);
    else setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    if (useOuterState) closeDropdown();
    else setAnchorEl(null);
  };
  const handleOptionClick = (option) => {
    onOptionClick(option);
    handleClose();
  };

  const stripLabel = (label) => {
    if (stripLength && label?.length > stripLength)
      return `${label.substr(0, stripLength)} ...`;
    return label;
  };

  const stateOrAnchor = state || anchorEl;

  return (
    <>
      <Button
        open={stateOrAnchor}
        onClick={handleClick}
        gridColumn={gridColumn}
        mwGridColumn={mwGridColumn}
        ref={ref}
        showSelectedBorder={
          showSelectedBorder && selectedOptionHasValueOrId(selectedOption)
        }
        className={className}
        buttonWidth={buttonWidth}
        $isDisabled={isDisabled}
      >
        {displayIcon ? (
          <IconWrapper className="iconWrapper">{displayIcon}</IconWrapper>
        ) : null}
        {selectedOption ? (
          stripLabel(
            selectedOption.label ||
              selectedOption.name ||
              selectedOption.display_name ||
              'No preference'
          )
        ) : (
          <H6 color={textColor || 'var(--color-text-secondary)'}>
            {placeholder}
          </H6>
        )}
        <StyledArrowDown
          open={stateOrAnchor}
          size="16px"
          color="var(--color-text-primary)"
        />
      </Button>
      <Menu
        anchorEl={stateOrAnchor}
        onClose={handleClose}
        width={menuWidth}
        height={menuHeight}
        disablePortal={disablePortal}
      >
        {topChildren}
        {options.map((option, index) => (
          <MenuItem
            displayCheck={displayCheck}
            selected={isSelected(selectedOption, option)}
            option={option}
            onClick={handleOptionClick}
            key={getKey(option) || index}
            links={links}
            color={menuColor}
            showIcon={showIcon}
          />
        ))}
        {children}
      </Menu>
    </>
  );
});

export default Dropdown;

Dropdown.propTypes = {
  selectedOption: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.any),
  onOptionClick: PropTypes.func.isRequired,
  displayCheck: PropTypes.bool,
  children: PropTypes.node,
  gridColumn: PropTypes.string,
  mwGridColumn: PropTypes.string,
  links: PropTypes.bool,
  stripLength: PropTypes.number,
  showSelectedBorder: PropTypes.bool,
  displayIcon: PropTypes.node,
  onClick: PropTypes.func,
  menuWidth: PropTypes.string,
  menuHeight: PropTypes.string,
  buttonWidth: PropTypes.string,
  placeholder: PropTypes.string,
  showIcon: PropTypes.bool,
  getKey: PropTypes.func,
  disablePortal: PropTypes.bool
};

Dropdown.defaultProps = {
  selectedOption: null,
  options: [],
  displayCheck: false,
  showIcon: false,
  children: null,
  gridColumn: 'span 4',
  mwGridColumn: 'auto',
  links: false,
  stripLength: null,
  showSelectedBorder: true,
  displayIcon: null,
  menuWidth: '233px',
  menuHeight: '276px',
  buttonWidth: null,
  onClick: () => {},
  placeholder: '',
  getKey: (option) => option?.value || option?.id,
  disablePortal: false
};
