import { schema } from 'normalizr';
import paginateAction from '@utils/paginateAction';
import { HOST_TYPE } from '@utils/api-helper/host';
import { quizTypes } from '../constants';
import { paginatorListSchema } from '../schemas';

export const fetchMyQuizAttempts = (quizUid) => (dispatch) =>
  dispatch({
    type: quizTypes.FETCH_MY_QUIZ_ATTEMPTS,
    endpoint: `v1/quizzes/quiz/${quizUid}/leaderboard/?limit=200`,
    method: 'get',
    quizUid,
    schema: paginatorListSchema
  });

export const updateSessionLanguage = (sessionUid, languageCode) => (dispatch) =>
  dispatch({
    type: quizTypes.UPDATE_SESSION_LANGUAGE,
    endpoint: `v1/quizzes/session/${sessionUid}/update_language/`,
    method: 'post',
    data: { language: languageCode }
  });

// quiz creation -->

export const createTest = (data, uid) => (dispatch) =>
  dispatch({
    type: quizTypes.EDIT_QUIZ,
    endpoint: `v2/quizzes/me/edit/${uid}/`,
    method: 'post',
    data
  });

export const uploadCSV = (file, quizUID) => (dispatch) => {
  const data = new FormData();
  data.append('file', file);
  data.append('quiz_uid', quizUID);

  return dispatch({
    type: quizTypes.UPLOAD_CSV,
    endpoint: 'v1/quizzes/quiz-question/upload/',
    method: 'post',
    isFormData: true,
    data
  });
};

export const fetchReviewStats = (goalUID) => ({
  type: quizTypes.FETCH_REVIEW_STATS,
  endpoint: `v1/quizzes/quiz-review/get_review_stats/?goal_uid=${goalUID}`,
  method: 'get',
  goalUID,
  schema: new schema.Object({}),
  processData: (data) => ({
    todayCount: data.today_count,
    thisMonthCount: data.this_month_count,
    lastMonthCount: data.last_month_count
  })
});

const getReviews = (key, resetWithoutEmpty) => (next) => ({
  type: quizTypes.FETCH_REVIEWS,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema
});

export const fetchReviews = (
  goalUID,
  filter,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    `v1/quizzes/quiz-review/get_reviews/?goal_uid=${goalUID}&status_filter=${filter}&limit=4`,
    getReviews(`${goalUID}_${filter}`, resetWithoutEmpty),
    'reviewTests',
    `${goalUID}_${filter}`,
    resetWithoutEmpty
  )(nextPage);

export const fetchReviewDashboardGoals = () => ({
  type: quizTypes.FETCH_DASHBOARD_GOALS,
  endpoint: 'v1/quizzes/quiz-review/dashbooard_goals/',
  method: 'get'
});

export const updateDashboardFilters = (goal, state, sort, language) => ({
  type: quizTypes.UPDATE_DASHBOARD_FILTERS,
  goal,
  state,
  sort,
  language
});

export const fetchPostReviewNotifications = (quizUID) => ({
  type: quizTypes.FETCH_POSTREVIEW_NOTIFICATIONS,
  endpoint: `v1/quizzes/quiz-review/review_notification/?quiz_uid=${quizUID}`,
  method: 'get'
});

export const assignReview = (quiz_uid, reviewer_email, self_review = 0) => ({
  type: quizTypes.ASSIGN_REVIEW,
  endpoint: 'v1/quizzes/quiz-review/assign_review/',
  method: 'post',
  data: {
    reviewer_email,
    quiz_uid,
    self_review
  }
});

export const assignEvalutor = (quiz_uid, email) => ({
  type: quizTypes.ASSIGN_EVALUATOR,
  endpoint: 'v1/quizzes/subjective-test/assign_quiz/',
  method: 'post',
  data: {
    quiz_uid,
    email
  }
});

export const assignUploader = (quiz_uid, email) => ({
  type: quizTypes.ASSIGN_UPLOADER,
  endpoint: 'v1/quizzes/quiz-upload/assign_uploader/',
  method: 'post',
  data: {
    quiz_uid,
    uploader_email: email
  }
});

export const fetchTestEvalDashStats = (goalUID) => ({
  type: quizTypes.FETCH_EVAL_DASH_STATS,
  endpoint: `v1/quizzes/subjective-test/get_statistics/?goal_uid=${goalUID}`,
  method: 'get',
  goalUID,
  schema: new schema.Object({}),
  processData: (data) => ({
    todayCount: data.today_count,
    thisMonthCount: data.this_month_count,
    lastMonthCount: data.last_month_count
  })
});

const getMyEvals = (key, resetWithoutEmpty) => (next) => ({
  type: quizTypes.FETCH_MY_EVALS,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema
});

export const fetchMyEvals = (
  goalUID,
  filter,
  language,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    `v1/quizzes/subjective-test/get_my_evaluations/?goal_uid=${goalUID}&status_filter=${filter}&language_code=${language}&limit=40`,
    getMyEvals(`${goalUID}_${filter}_${language}`, resetWithoutEmpty),
    'myEvaluations',
    `${goalUID}_${filter}_${language}`,
    resetWithoutEmpty
  )(nextPage);

const getEvaluatedCopies = (key, resetWithoutEmpty) => (next) => ({
  type: quizTypes.FETCH_EVALUATED_COPIES,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema
});

export const fetchEvaluatedCopies = (
  quizUID,
  sortBy,
  language,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    `v1/quizzes/subjective-test/view_completed_evaluations/?quiz_uid=${quizUID}&sort_by=${sortBy}&language_code=${language}`,
    getEvaluatedCopies(`${quizUID}_${sortBy}`, resetWithoutEmpty),
    'evaluatedCopies',
    `${quizUID}_${sortBy}`,
    resetWithoutEmpty
  )(nextPage);

export const fetchEvalDashboardGoals = () => ({
  type: quizTypes.FETCH_EVAL_DASH_GOALS,
  endpoint: 'v1/quizzes/subjective-test/get_dashboard_goals/',
  method: 'get'
});

export const assignEvaluation = (quiz_uid) => ({
  type: quizTypes.ASSIGN_EVALUATION,
  endpoint: 'v1/quizzes/subjective-test/assign_evaluation/',
  method: 'post',
  data: { quiz_uid }
});

export const fetchTestSeriesData = (testSeriesKey) => ({
  type: quizTypes.FETCH_TEST_SERIES_DATA,
  endpoint: `api/test-series/${testSeriesKey}/`,
  method: 'get',
  hostType: HOST_TYPE.CMS,
  testSeriesKey
});

export const fetchGtpTestSeriesData = (goalUID) => ({
  type: quizTypes.FETCH_GTP_TEST_SERIES_DATA,
  endpoint: `v1/quizzes/gtp/test_series/?goal_uid=${goalUID}`,
  method: 'get'
});

export const submitCriteriaScore = (
  evaluation_uid,
  criteria,
  remarks,
  evaluated_sheet_url
) => ({
  type: quizTypes.SUBMIT_EVALUATION_SCORE,
  endpoint: 'v1/quizzes/subjective-test/give_criteria_score/',
  method: 'post',
  data: {
    evaluation_uid,
    attributes: { criteria },
    remarks,
    evaluated_sheet_url
  }
});

export const fetchQuizUploadDashboardGoals = () => ({
  type: quizTypes.FETCH_QUIZ_UPLOAD_DASHBOARD_GOALS,
  endpoint: 'v1/quizzes/quiz-upload/dashboard_goals/',
  method: 'get'
});

const getMyUploads = (key, resetWithoutEmpty) => (next) => ({
  type: quizTypes.FETCH_MY_UPLOADS,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema
});

export const fetchMyUploads = (
  goalUID,
  filter,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    `v1/quizzes/quiz-upload/my_uploads/?goal_uid=${goalUID}&status_filter=${filter}&limit=6`,
    getMyUploads(`${goalUID}_${filter}`, resetWithoutEmpty),
    'myUploads',
    `${goalUID}_${filter}`,
    resetWithoutEmpty
  )(nextPage);
