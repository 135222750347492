import Head from 'next/head';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { NextSeo } from 'next-seo';
import ProdigyContainer from '@cont/Prodigy/ProdigyContainer';
import { fetchTestSeriesData } from '@actions/quizzes';
import setupInitialProps from '@utils/setupInitialProps';
import { redirectToRoute } from '@utils/route';
import Layout from '@layout/FreeLayout/FreeLayout';
import PageReduxProvider from '@cont/App/PageReduxProviderWrapper';

const TestSeries = () => {
  const { query } = useRouter();
  const { testSeriesKey } = query;
  const data = useSelector(
    ({ quizzes }) => quizzes.testSeries?.[testSeriesKey] || null
  );
  const { metaData = {} } = data;
  const { title, description, ogImage, twitterOgImage } = metaData;
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          title,
          description,
          images: [
            {
              url: ogImage
            }
          ]
        }}
        twitter={{
          images: [{ url: twitterOgImage }]
        }}
      />
      <Head>
        <title>{metaData.title}</title>
      </Head>
      <ProdigyContainer testSeriesKey={testSeriesKey} data={data} />
    </>
  );
};

TestSeries.pageInfo = {
  isMWebSupported: true,
  layoutOptions: {
    hideSearch: true,
    hideGoalSelector: true,
    hideLearnerMinutes: true,
    hideNudges: true,
    disableDarkMode: true,
    hideIntercom: true,
    hideFreshbot: true
  }
};

TestSeries.getInitialProps = setupInitialProps(
  async ({ store, query, isServer, res }) => {
    const { testSeriesKey } = query;
    if (!store.getState().quizzes.testSeries?.[testSeriesKey]) {
      const response = await store.dispatch(fetchTestSeriesData(testSeriesKey));
      if (!response || response.error)
        return redirectToRoute(isServer, res, '/404');
    }
  },
  TestSeries.pageInfo
);

export default TestSeries;
TestSeries.Layout = Layout;
TestSeries.Wrapper = PageReduxProvider;
