/* eslint-disable @next/next/no-img-element */
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Spacings, breakpoints } from '@styles/index';
import { useIsMobile } from '@stores/AppCommonData';
import boxShadowGenerator from '@utils/boxShadowGenerator';

const Wrapper = styled.div`
  cursor: pointer;
  height: 500px;
  width: 1136px;
  margin: auto;
  margin-bottom: 160px;
  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    padding: ${Spacings.SPACING_4B};
    margin-bottom: 80px;
    height: auto;
  }
  img {
    width: 100%;
    height: 100%;
    box-shadow: ${boxShadowGenerator()};
    border-radius: 8px;
  }
`;

const PromotionalBanner = ({ data }) => {
  const isMobile = useIsMobile();
  return (
    <Wrapper
      onClick={data.permalink ? () => window.open(data.permalink) : null}
    >
      <img
        src={isMobile ? data.mwImage : data.webImage}
        alt="promotional-banner"
      />
    </Wrapper>
  );
};

export default PromotionalBanner;

PromotionalBanner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired
};
