import pushRoute from './pushRoute';

// eslint-disable-next-line import/prefer-default-export
export const redirectToRoute = (isServer, res, href, as) => {
  const asPath = as || href;
  if (isServer) {
    res.writeHead(302, {
      Location: asPath
    });
    return res.end();
  }
  pushRoute(href, asPath);
  return {};
};
